

import { BannerImg, ImageInner, BannerData, StyledBtnBox, BannerButton, BannerPage, TextCenter, BannerButtonRequest } from './Banner.style';
import React, { useCallback,  useState } from 'react';
import { Trans } from 'react-i18next';
import { StyledLabel } from '@components/Text';
import { useSelector } from "react-redux";

import ModalRequest from './ModalReuqest/ModalRequest';
import { LabelFFF } from '@layouts/Default/components/NavBox/NavBox.style';
import { DisplayH1, ParagraphColor, Spacing, SpanColor } from "@pages/CommonStyle/CommonStyle";
import { CommonState } from '@redux/reducers/commonReducer';
import home from '@src/lang/ko/home/home';
import button from '@src/lang/ko/button';



const Banner = () => {

  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);
  
  const onRequest = useCallback(() => {
    setModalRequest(false);
  }, [])


  const [isModalRequest, setModalRequest] = useState(false);
  const onClickToggleModalRequest = useCallback(() => {
    //setModalRequest(!isModalRequest);
  }, []);

  const onDownload = () => {

  }


  return (
    <BannerPage>
      <BannerImg img={home.banner.img[layoutType]}/>

      <ImageInner>

        <BannerData>
          
          <DisplayH1 size="medium">
            <SpanColor color='#FFF'>
              <Trans i18nKey={`${home.banner.title}`} />
            </SpanColor>
          </DisplayH1>

          <Spacing pixel={'16'}/>

          <TextCenter>
            <ParagraphColor color='#FFF' size="large">
              <Trans i18nKey={`${home.banner.desc}`} />
            </ParagraphColor>
          </TextCenter>

          <Spacing pixel={'32'}/>

          <StyledBtnBox>
            <BannerButton onClick={onClickToggleModalRequest}>
              <LabelFFF size="large">
                <Trans i18nKey={button.service} />
              </LabelFFF>
            </BannerButton>
            <BannerButtonRequest onClick={() => onDownload()}>
              <StyledLabel size="large">
                <Trans i18nKey={button.proposal} />
              </StyledLabel>
            </BannerButtonRequest>
          </StyledBtnBox>
        </BannerData>
      </ImageInner>


      {isModalRequest && (
        <ModalRequest onClickToggleModal={onRequest}>
        </ModalRequest>
      )}

    </BannerPage>
  )
};

export default Banner;