import styled from '@emotion/styled';
import { StyledGridColumnProps } from './Grid.model';

interface LayoutInfo {
  breakPoint: number;
  padding: number;
  gap: number;
  columns: number;
}

const DESKTOP: LayoutInfo = {
  breakPoint: 1280,
  gap: 32,
  padding: 24,
  columns: 12,
};

const TABLET: LayoutInfo = {
  breakPoint: 960,
  gap: 32,
  padding: 24,
  columns: 8,
};

const MOBILE: LayoutInfo = {
  breakPoint: 768,
  gap: 32,
  padding: 16,
  columns: 4,
};

let a : 360;

export const SCREEN_BREAKPOINT = {
  DESKTOP: DESKTOP.breakPoint,
  TABLET: TABLET.breakPoint,
  MOBILE: MOBILE.breakPoint,
  TABLET_MEDIA_QUERY: `@media only screen and (min-width: ${MOBILE.breakPoint + 1}px) and (max-width: ${TABLET.breakPoint}px)`,
  MOBILE_MEDIA_QUERY: `@media only screen and (max-width: ${MOBILE.breakPoint}px)`,
};

export const StyledGridContainer = styled.div`
  width: 100%;
  position: relative;
  justify-content: center;
  margin: 0 auto;

  max-width: ${DESKTOP.breakPoint}px;
  padding: 0 ${DESKTOP.padding}px;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    padding: 0 ${TABLET.padding}px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding: 0 ${MOBILE.padding}px;
  }
`;

interface StyledGridRowProps {
  desktop?: number
  tablet?: number
  mobile?: number
}

export const StyledGridRow = styled.div<StyledGridRowProps>`
  width: 100%;
  position: relative;
  gap: 32px;
  flex-wrap: wrap;
  display: grid;
  display: -ms-grid;

  -ms-grid-columns: repeat(${({ desktop = DESKTOP.columns }) => desktop}, 1fr);
  grid-template-columns: repeat(${({ desktop = DESKTOP.columns }) => desktop}, 1fr);

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    -ms-grid-columns: repeat(${({ tablet = TABLET.columns }) => tablet}, 1fr);
    grid-template-columns: repeat(${({ tablet = TABLET.columns }) => tablet}, 1fr);
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    -ms-grid-columns: repeat(${({ mobile = MOBILE.columns }) => mobile}, 1fr);
    grid-template-columns: repeat(${({ mobile = MOBILE.columns }) => mobile}, 1fr);
    row-gap: 16px;
  }
`;

export const StyledGridColumn = styled.div<StyledGridColumnProps>`
  position: relative;
  display: grid;

  -ms-grid-column-span: ${({ desktop }) => desktop};
  grid-column-end: span ${({ desktop }) => desktop};

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    -ms-grid-column-span: ${({ tablet }) => tablet};
    grid-column-end: span ${({ tablet }) => tablet};
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    -ms-grid-column-span: ${({ mobile }) => mobile};
    grid-column-end: span ${({ mobile }) => mobile};
  }
`;


export const StyledGridColumnBlank = styled.div<StyledGridColumnProps>`
  position: relative;
  display: grid;

  -ms-grid-column-span: ${({ desktop }) => desktop};
  grid-column-end: span ${({ desktop }) => desktop};


  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    display : none;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    display : none;
    
  }
`;


export const StyledGridColumnSub = styled.div<StyledGridRowProps>`
    position: relative;
    display: grid;

    -ms-grid-column-span: ${({ desktop }) => desktop};
    grid-column-end: span ${({ desktop }) => desktop};

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
      -ms-grid-column-span: 2.1;
      grid-column-end: span 2.1;
    }

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
      -ms-grid-column-span: ${({ mobile }) => mobile};
      grid-column-end: span ${({ mobile }) => mobile};
    }
`;




export const StyledGridColumnRev = styled.div<StyledGridColumnProps>`

  order : 1 ;
  position: relative;
  display: grid;
  

  -ms-grid-column-span: ${({ desktop }) => desktop};
  grid-column-end: span ${({ desktop }) => desktop};

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {

  order : 1 ;
    -ms-grid-column-span: ${({ tablet }) => tablet};
    grid-column-end: span ${({ tablet }) => tablet};
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {

  order : 2 ;
    -ms-grid-column-span: ${({ mobile }) => mobile};
    grid-column-end: span ${({ mobile }) => mobile};
  }
`;




export const StyledGridColumnRev2 = styled.div<StyledGridColumnProps>`

  order : 2 ;
  position: relative;
  display: grid;

  
  -ms-grid-column-span: ${({ desktop }) => desktop};
  grid-column-end: span ${({ desktop }) => desktop};

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {

  order : 2 ;
    -ms-grid-column-span: ${({ tablet }) => tablet};
    grid-column-end: span ${({ tablet }) => tablet};
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {

  order : 1 ;
    -ms-grid-column-span: ${({ mobile }) => mobile};
    grid-column-end: span ${({ mobile }) => mobile};
  }
`;





export const StyledGridRowOS = styled.div<StyledGridRowProps>`
  width: 100%;

  position: relative;
  padding-top : 16px;

  display: flex;
  flex-direction: row;


  -ms-grid-columns: repeat(${({ desktop = DESKTOP.columns }) => desktop}, 1fr);
  grid-template-columns: repeat(${({ desktop = DESKTOP.columns }) => desktop}, 1fr);

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    -ms-grid-columns: repeat(${({ tablet = TABLET.columns }) => tablet}, 1fr);
    grid-template-columns: repeat(${({ tablet = TABLET.columns }) => tablet}, 1fr);
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    -ms-grid-columns: repeat(${({ mobile = MOBILE.columns }) => mobile}, 1fr);
    grid-template-columns: repeat(${({ mobile = MOBILE.columns }) => mobile}, 1fr);
    row-gap: 16px;
  }
`;




export const StyledGridRowBtn = styled.div<StyledGridRowProps>`
  
  position: relative;
  
  display: grid;
  display: -ms-grid;
  

  -ms-grid-columns: repeat(${({ desktop = DESKTOP.columns }) => desktop}, 1fr);
  grid-template-columns: repeat(${({ desktop = DESKTOP.columns }) => desktop}, 1fr);

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    -ms-grid-columns: repeat(${({ tablet = TABLET.columns }) => tablet}, 1fr);
    grid-template-columns: repeat(${({ tablet = TABLET.columns }) => tablet}, 1fr);
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    -ms-grid-columns: repeat(${({ mobile = MOBILE.columns }) => mobile}, 1fr);
    grid-template-columns: repeat(${({ mobile = MOBILE.columns }) => mobile}, 1fr);
    row-gap: 16px;
  }


`;

