import React from 'react';

import './Footer.styl';
import {
  StyledFooter,
  StyledFooterBottom,
  StyledFooterATag,
  StyledFooterBottomParagraph,
  StyledFooterBottomRow,
  StyledFooterLogo,
  StyledFooterBottomTitle,
  A646464,
} from './Footer.style';
import {  StyledGridColumn, StyledGridContainer } from '@components/Grid/Grid.style';

import { StyledLabel } from '@components/Text';

import { Trans } from 'react-i18next';
import footer from '@src/lang/ko/footer';
import { logoBlack } from '@assets/images/logo';
import { Spacing } from '@pages/CommonStyle/CommonStyle';

const Footer = () => {

  return (
    <StyledFooter>


      <StyledGridContainer>

        <StyledGridColumn desktop={2} tablet={2} mobile={4}>
          <StyledFooterLogo src={logoBlack} alt='ORBRO Logo'/>
        </StyledGridColumn>

        <Spacing pixel={'36'}/>


        <StyledFooterBottom>
          <StyledFooterBottomTitle>
            <StyledLabel size="medium"><Trans i18nKey={footer.info.copyright} /></StyledLabel>
          </StyledFooterBottomTitle>

          <StyledFooterBottomRow>
            <StyledFooterBottomParagraph size="small">
              <A646464 href='https://kong-tech.com' target='_blank' rel='noopenner'>
                <Trans i18nKey={footer.info.info} />
              </A646464>
              <Trans i18nKey={footer.info.info2} />
              <Trans i18nKey={footer.info.info3} />
            </StyledFooterBottomParagraph>
          </StyledFooterBottomRow>

          <Spacing pixel={'40'} />
          <StyledFooterBottomRow columnGap={32}>
            <StyledFooterBottomParagraph size="small">
              <StyledFooterATag href="https://orbro.io/sign-up-conditions/:2" target="_blank">
                <Trans i18nKey={footer.info.privacyPolicy} />
              </StyledFooterATag>
            </StyledFooterBottomParagraph>

            <StyledFooterBottomParagraph size="small">
              <StyledFooterATag href="https://orbro.io/sign-up-conditions/:1" target="_blank">
                <Trans i18nKey={footer.info.servicePolicy} />
              </StyledFooterATag>
            </StyledFooterBottomParagraph>

            <StyledFooterBottomParagraph size="small">
              <StyledFooterATag href="https://orbro.io/sign-up-conditions/:3" target="_blank">
                <Trans i18nKey={footer.info.locationPolicy} />
              </StyledFooterATag>
            </StyledFooterBottomParagraph>
          </StyledFooterBottomRow>
        </StyledFooterBottom>

      </StyledGridContainer>
    </StyledFooter>
  );
};

export default Footer;

