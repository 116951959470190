import React, { PropsWithChildren, useState, useRef, useEffect } from "react";

import {
  StyledDownloadPolicyBox, DialogRequestBox, DialogInput, ClearButton, ClearIcon, 
  DialogCheckBox, DialogRequestBtn } from "./Modal.style";

import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { IconCheckBoxEmpty, IconCheckBoxOn, IconCloseIcon} from "@assets/images/icon/";
import emailjs from 'emailjs-com';
import { StyledDisplay, StyledLabel, StyledParagraph } from "@components/Text";
import { useLocation } from "react-router-dom";
import { InputBox, InputDescBox, RequestBox, RequestColor, RequestInputBox, RequestPage } from "./Request.style";
import { StyledGridColumn, StyledGridContainer, StyledGridRow } from "@components/Grid/Grid.style";
import { CommonState } from "@redux/reducers/commonReducer";
import home from "@src/lang/ko/home/home";
import { LabelColor, Spacing, SpanBlueUnderline, SpanUnderline } from "@pages/CommonStyle/CommonStyle";
import { Div100 } from "@pages/CommonStyle/CommonDivStyle";

export const emailData = React.createContext('');



const Request: React.FC<PropsWithChildren> = () => {

  const dataPath = home.request;
  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);
  const location = useLocation();
  // Input event
  const handleInput = (event, callback) => {
    const value = event.target.value;
    callback(value);
  }

  // Name Handle
  const [ name, setName  ] = useState<string>('');
  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
    console.log(name);
  };
  const handleClearName = () => {
    setName('');
  };

  // Company Handle
  const [ company, setCompany ] = useState<string>('');
  const onChangeCompany = (e) => {
    const company = e.target.value;
    setCompany(company);
  };
  const handleClearCompany = () => {
    setCompany('');
  };

  // Email Handle
  const [ email, setEmail ] = useState<string>('');
  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };
  const handleClearEmail = () => {
    setEmail('');
  };

  // Phone Handle
  const [ phone, setPhone ] = useState<string>('');
  const onChangePhone = (e) => {
    const phone = e.target.value;
    setPhone(phone);
  };
  const handleClearPhone = () => {
    setPhone('');
  };

  // Request Handle
  const [ request, setRequest ] = useState<string>('');
  const onChangeRequest = (e) => {
    const request = e.target.value;
    setRequest(request);
  };

  const handleClearRequest = () => {
    setRequest('');
  };

  const componentRef = useRef<HTMLDivElement>(null);

  const [focus, setFocus] = useState({
    name: false,
    company: false,
    email: false,
    phone: false,
    request: false,
  });
  

  const handleFocus = (field) => {
    setFocus((prevFocus) => {
      const updatedFocus = {} as {
        name: boolean;
        company: boolean;
        email: boolean;
        phone: boolean;
        request: boolean;
      };
      for (const key in prevFocus) {
        updatedFocus[key] = key === field;
      }
      return updatedFocus;
    });
  };
  
  const handleKeyDown = (e, currentField) => {
    if (e.key === "Tab") {
      e.preventDefault();
  
      const fields = ["name", "company", "email", "phone", "request"];
      const currentFieldIndex = fields.indexOf(currentField);
      const nextFieldIndex = currentFieldIndex === fields.length - 1 ? 0 : currentFieldIndex + 1;
      const nextField = fields[nextFieldIndex];
  
      const nextInputElement = document.getElementById(`${nextField}-input`);
      if (nextInputElement) {
        nextInputElement.focus();
      }
    }
  };
  
  
  const [checked, setChecked] = useState(false);
  const [submit, setSubmit] = useState(false);

  const policyCheck = () => {
    setChecked(!checked);
  };

  // Focus Handling
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (componentRef.current && !componentRef.current.contains(e.target)) {
        setFocus({
          name: false,
          company: false,
          email: false,
          phone: false,
          request: false,
        });
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  

  useEffect(() => {
    if(name && company && email && phone && checked){
      setSubmit(true);
    } else{
      setSubmit(false);
    }
  }, [name, company, email, phone, checked]);

 

  const onSubmit = () => {
    if(submit){
      emailjs.init('SoQtE3CThBY_wpeDn');
      const mailForm ={
        company : company,
        name : name,
        phone : phone,
        email : email,
      }

      let alertMessage;
      if(location.pathname.includes('/en/')){
        alertMessage = "Thank you for your inquiry. We'll get back to you as soon as possible. Thank you."
      } else if (location.pathname.includes('/jp/')){
        alertMessage = "お問い合わせありがとうございます。 できるだけ早くご連絡いたします。 ありがとう"
      }else{
        alertMessage = "문의주셔서 감사합니다. 빠른 시일내로 다시 연락드리겠습니다. 감사합니다."
      }

      // 이메일 보내기
      emailjs.send('service_7yv5b7d', "template_l8mi5e4", mailForm)
      .then((response) => {
        console.log('이메일 보내기 성공:', response);
      })
      .catch((error) => {
        console.error('이메일 보내기 실패:', error);
      });
      alert(alertMessage);
      handleClearCompany();
      handleClearEmail();
      handleClearName();
      handleClearPhone();
      handleClearRequest();
      handleFocus('');
      setChecked(false);
    } 
    
  };



  return (
    <RequestColor>
      <StyledGridContainer>
        <StyledGridRow>
          {layoutType === 'desktop' &&
            <StyledGridColumn desktop={2} tablet={1} mobile={0}/>
          }

          <StyledGridColumn desktop={8} tablet={8} mobile={4}>
          <RequestPage>

            <StyledDisplay size='small'>
              <Trans i18nKey={home.request.title}/>
            </StyledDisplay>

            <Spacing pixel="16"/>

            <StyledParagraph size='medium'>
              <span>
                <Trans i18nKey={home.request.desc1}/>
              </span>
              <SpanBlueUnderline>
                <Trans i18nKey={home.request.desc2}/>
              </SpanBlueUnderline>
              <span>
                <Trans i18nKey={home.request.desc3}/>
              </span>
              
            </StyledParagraph>

            <Spacing pixel="48"/>

            <DialogRequestBox>

              <RequestBox>
                {/* 회사 입력 */}
                <InputBox>
                  <InputDescBox>
                    <LabelColor size="medium" color={"#000"}> <Trans i18nKey={ home.request.company}/> </LabelColor> 
                  </InputDescBox>
                  <Spacing pixel="8"/>
                  <RequestInputBox inputValue={focus.company}>
                    <Div100>
                      <DialogInput 
                        value={company}
                        placeholder={ dataPath.companyPH || '' }
                        onInput={(e) => { handleInput(e, setCompany) }}
                        onChange={onChangeCompany}
                        onClick={() => handleFocus('company')}
                        onKeyDown={(e) => handleKeyDown(e, "company")}
                      />

                      {company !== '' && focus.company && (
                        <ClearButton onClick={handleClearCompany}>
                          <ClearIcon src={IconCloseIcon} alt="close icon"/>
                        </ClearButton>
                      )}
                    </Div100>
                  </RequestInputBox>
                </InputBox>

                {/* 이름 입력 */}
                <InputBox>
                  <InputDescBox>
                    <LabelColor size="medium" color={"#000"}> <Trans i18nKey={ home.request.name}/> </LabelColor> 
                  </InputDescBox>
                  <Spacing pixel="8"/>
                  <RequestInputBox inputValue={focus.name}>
                    <Div100>
                      <DialogInput 
                        value={name}
                        placeholder={ dataPath.namePH }
                        onInput={(e) => { handleInput(e, setName) }}
                        onChange={onChangeName}
                        onClick={() => handleFocus('name')}
                        onKeyDown={(e) => handleKeyDown(e, "name")}
                      />

                      {name !== '' && focus.name &&(
                        <ClearButton onClick={handleClearName}>
                          <ClearIcon src={IconCloseIcon} alt="close icon"/>
                        </ClearButton>
                      )}
                    </Div100>
                  </RequestInputBox>
                </InputBox>
              </RequestBox>

              <Spacing pixel="32"/>

              <RequestBox>

                {/* 전화번호 입력 */}
                <InputBox>
                  <InputDescBox>
                    <LabelColor size="medium" color={"#000"}> <Trans i18nKey={ home.request.phone}/> </LabelColor> 
                  </InputDescBox>
                  <Spacing pixel="8"/>
                  <RequestInputBox inputValue={focus.phone}>
                  <Div100>
                    <DialogInput 
                      value={phone}
                      placeholder={ dataPath.phonePH }
                      onInput={(e) => { handleInput(e, setPhone) }}
                      onChange={onChangePhone}
                      onClick={() => handleFocus('phone')}
                      onKeyDown={(e) => handleKeyDown(e, "name")}
                    />

                    {phone !== '' && focus.phone && (
                      <ClearButton onClick={handleClearPhone}>
                        <ClearIcon src={IconCloseIcon} alt="close icon"/>
                      </ClearButton>
                    )}
                  </Div100>
                </RequestInputBox>
                </InputBox>

                {/* 이메일 입력 */}
                <InputBox>
                  <InputDescBox>
                    <LabelColor size="medium" color={"#000"}> <Trans i18nKey={ home.request.email}/> </LabelColor> 
                  </InputDescBox>
                  <Spacing pixel="8"/>
                  <RequestInputBox inputValue={focus.email}>
                    <Div100>
                      <DialogInput 
                        value={email}
                        placeholder={  dataPath.emailPH }
                        onInput={(e) => { handleInput(e, setEmail) }}
                        onChange={onChangeEmail}
                        onClick={() => handleFocus('email')}
                        onKeyDown={(e) => handleKeyDown(e, "name")}
                      />

                      {email !== '' && focus.email && (
                        <ClearButton onClick={handleClearEmail}>
                          <ClearIcon src={IconCloseIcon} alt="close icon"/>
                        </ClearButton>
                      )}
                    </Div100>
                  </RequestInputBox>
                </InputBox>
              </RequestBox>
              
              <Spacing pixel="32"/>

              <InputDescBox>
                <StyledDownloadPolicyBox>
                  {checked ? (
                    <DialogCheckBox src={IconCheckBoxOn} onClick={policyCheck} alt="Checkbox Icon"/>
                  ) : (
                    <DialogCheckBox src={IconCheckBoxEmpty} onClick={policyCheck} alt="Checkbox Icon"/>
                  )}
                  
                  <StyledLabel size={"large"}>
                    <span>
                      <Trans i18nKey={ home.request.policy1}/>
                    </span>
                    <SpanUnderline>
                      <Trans i18nKey={ home.request.policy2}/>
                    </SpanUnderline>
                    <span>
                      <Trans i18nKey={ home.request.policy3}/>
                    </span>
                  </StyledLabel>
                  
                </StyledDownloadPolicyBox>
              </InputDescBox>

              <Spacing pixel="32"/>

              <DialogRequestBtn submit={submit} onClick={onSubmit}>  
                <LabelColor color='#FFFFFF' size="medium">
                  <Trans i18nKey={home.request.submit}/>
                </LabelColor>
              </DialogRequestBtn>



            </DialogRequestBox>
                
          </RequestPage>
          </StyledGridColumn>
        </StyledGridRow>
      </StyledGridContainer>
    </RequestColor>
  )
};

export default Request;