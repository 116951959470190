import { TypeMap } from '@core/util';
import styled from '@emotion/styled';
import { CommonText, CommonTextProps } from '../CommonText.style';
import { SCREEN_BREAKPOINT } from '@components/Grid/Grid.style';

type SizeTypes = 'xxlarge' | 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall' | 'xxsmall' | 'xxxsmall' | 'xxxxsmall';
interface StyledHeadingProps extends CommonTextProps {
  size: SizeTypes;
}

type HeadingData = {
  size: string,
  weight: string,
  height: string,
}

const sizeMap: TypeMap<SizeTypes, HeadingData> = {
  xxlarge: { size: '40px', weight: 'bold', height: '1.3' },
  xlarge: { size: '36px', weight: 'bold', height: '1.22' },
  large: { size: '32px', weight: 'bold', height: '1.25' },
  medium: { size: '28px', weight: 'bold', height: '1.29' },
  small: { size: '24px', weight: 'bold', height: '1.33' },
  xsmall: { size: '20px', weight: '600', height: '1.4' },
  xxsmall: { size: '18px', weight: '600', height: '1.33' },
  xxxsmall: { size: '16px', weight: '600', height: '1.25' },
  xxxxsmall: { size: '14px', weight: '600', height: '1.14' }
};


const tabletSizeMap : TypeMap<SizeTypes, HeadingData> = {
  xxlarge: { size: '36px', weight: 'bold', height: '1.22' },
  xlarge: { size: '32px', weight: 'bold', height: '1.25' },
  large: { size: '28px', weight: 'bold', height: '1.29' },
  medium: { size: '24px', weight: 'bold', height: '1.33' },
  small: { size: '20px', weight: '600', height: '1.4' },
  xsmall: { size: '18px', weight: '600', height: '1.33' },
  xxsmall: { size: '16px', weight: '600', height: '1.25' },
  xxxsmall: { size: '14px', weight: '600', height: '1.14' },
  xxxxsmall: { size: '10px', weight: '600', height: '1.10' },
};

const mobileSizeMap : TypeMap<SizeTypes, HeadingData> = {
  xxlarge: { size: '36px', weight: 'bold', height: '1.22' },
  xlarge: { size: '32px', weight: 'bold', height: '1.25' },
  large: { size: '28px', weight: 'bold', height: '1.29' },
  medium: { size: '24px', weight: 'bold', height: '1.33' },
  small: { size: '20px', weight: '600', height: '24px' },
  xsmall: { size: '18px', weight: '600', height: '1.33' },
  xxsmall: { size: '16px', weight: '600', height: '1.25' },
  xxxsmall: { size: '14px', weight: '600', height: '1.14' },
  xxxxsmall: { size: '10px', weight: '600', height: '1.10' },
};



export const StyledHeading = styled(CommonText)<StyledHeadingProps>`
  font-size: ${({ size }) => sizeMap[size].size};
  font-weight: ${({ size }) => sizeMap[size].weight};
  line-height: ${({ size }) => sizeMap[size].height};


  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    font-size: ${({ size }) => tabletSizeMap[size].size}; 
    line-height: ${({ size }) => tabletSizeMap[size].height};
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    font-size: ${({ size }) => mobileSizeMap[size].size};
    line-height: ${({ size }) => mobileSizeMap[size].height};
  }

`;
