import React, { useCallback, useEffect, useState } from 'react';

import './NavBox.styl';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CommonState } from '@redux/reducers/commonReducer';

import {
  StyledHeaderContainer, StyledNavBox,
  StyledHeaderLogo, PricingBtn, ImgCursor, LabelFFF, NavContainer
} from './NavBox.style';

import { Trans, } from 'react-i18next';

import MenuOpenIconLight from '@assets/icons/ic-triple-line-light.svg';
import { logoWhite } from '@assets/images/logo';
import ModalRequest from '@pages/Home/Components/Banner/ModalReuqest/ModalRequest';

const NavBoxApart = () => {


  const checkRoot = () => {
    if (location.pathname === '/'
      || location.pathname === '/en/'
      || location.pathname === '/jp/'
      || location.pathname === '/cn/') {
      return true;
    }
    else {
      return false;
    }

  }

  // React
  const location = useLocation();

  // States
  const [isShowMobileNav, setIsShowMobileNav] = useState<boolean>(false);
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const [isServicePage, setIsServicePage] = useState<boolean>(false);



  useEffect(() => {
    const [, path] = location.pathname.split('/');
    setIsServicePage(path === 'services');
    setIsOpenMenu(false);
  }, [location]);

  useEffect(() => {
    const handleResize = () => {

    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [isDark, setDark] = useState<boolean>(false);
  useEffect (() => {
    if(checkRoot()){
      setDark(true);
    } else if (location.pathname.includes('enterprise') && !location.pathname.includes('techno')){
      setDark(false);
    } else{
      setDark(false);
    }

  },[location.pathname])

  const [isModalRequest, setModalRequest] = useState(false);
  const onClickToggleModalRequest = useCallback(() => {
    //setModalRequest(!isModalRequest);
  }, []);

  const onRequest = useCallback(() => {
    setModalRequest(false);
  },[])

  
  
  return (
    <>
      <StyledNavBox isServicePage={isServicePage} position={'absolute'} isGNBFixed={false}>
        <StyledHeaderContainer>
          <Link to={`/`}>
            <NavContainer>
              <StyledHeaderLogo src={logoWhite} alt='ORBRO Logo' />
            </NavContainer>
          </Link>

          {isShowMobileNav === false ? (
            <>
              <PricingBtn onClick={onClickToggleModalRequest}>
                <LabelFFF size='small'>
                  <Trans i18nKey={'도입 문의'} />
                </LabelFFF>    
              </PricingBtn>
            </>
          ) : (
            <ImgCursor
              src={isDark ? MenuOpenIconLight : MenuOpenIconLight} alt='ArrowIcon' 
              className="icon"
              onClick={(e) => {
                e.stopPropagation();
                setIsOpenMenu(!isOpenMenu);
              }}
            />
          )}
        </StyledHeaderContainer>
      </StyledNavBox>


      {isModalRequest && (
        <ModalRequest onClickToggleModal={onRequest}>
        </ModalRequest>
      )}


    </>
  );
};

export default NavBoxApart;
