import { TypeMap } from '@core/util';
import { Theme } from '@emotion/react';

type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
type HEX = `#${string}`;

export type Color = RGB | RGBA | HEX | 'transparent';

export const BLACK: Color = '#000';
export const WHITE: Color = '#fff';

// Blue
export const BLUE_50: Color = '#EAF1FD';
export const BLUE_100: Color = '#D7F4F0';
export const BLUE_200: Color = '#BFD4FA';
export const BLUE_300: Color = '#AAC6F9';
export const BLUE_400: Color = '#7FA9F6';
export const BLUE_500: Color = '#558DF3';
export const BLUE_600: Color = '#2A70F0';
export const BLUE_700: Color = '#225AC0';
export const BLUE_800: Color = '#194390';
export const BLUE_900: Color = '#112D60';

// Teal
export const TEAL_50: Color = '#EBFAF8';
export const TEAL_100: Color = '#D7F4F0';
export const TEAL_200: Color = '#C2EFE9';
export const TEAL_300: Color = '#AEEAE2';
export const TEAL_400: Color = '#86DFD3';
export const TEAL_500: Color = '#5DD5C4';
export const TEAL_600: Color = '#35CAB6';
export const TEAL_700: Color = '#2AA192';
export const TEAL_800: Color = '#20796D';
export const TEAL_900: Color = '#155149';

// Purple
export const PURPLE_50: Color = '#F5EAF8';
export const PURPLE_100: Color = '#EBD5F0';
export const PURPLE_200: Color = '#E1C0E9';
export const PURPLE_300: Color = '#D7ACE2';
export const PURPLE_400: Color = '#C382D3';
export const PURPLE_500: Color = '#B059C4';
export const PURPLE_600: Color = '#9C2FB6';
export const PURPLE_700: Color = '#7D2692';
export const PURPLE_800: Color = '#5E1C6D';
export const PURPLE_900: Color = '#3E1349';

// Gray
export const GRAY_50: Color = '#F8F8F8';
export const GRAY_100: Color = '#E9E9E9';
export const GRAY_200: Color = '#DADADA';
export const GRAY_300: Color = '#BDBDBD';
export const GRAY_400: Color = '#9F9F9F';
export const GRAY_500: Color = '#818181';
export const GRAY_600: Color = '#646464';
export const GRAY_700: Color = '#505050';
export const GRAY_800: Color = '#3C3C3C';
export const GRAY_900: Color = '#282828';


// Background
export const BACKGROUND_PRIMARY: Color = WHITE;
export const BACKGROUND_SECONDARY: Color = GRAY_50;
export const BACKGROUND_TERTIARY: Color = GRAY_100;
export const BACKGROUND_TRANSLUCENT: Color = 'rgba(255, 255, 255, 0.75)';

export const BACKGROUND_DARK_PRIMARY: Color = BLACK;
export const BACKGROUND_DARK_SECONDARY: Color = GRAY_900;
export const BACKGROUND_DARK_TERTIARY: Color = GRAY_800;
export const BACKGROUND_DARK_TRANSLUCENT: Color = 'rgba(0, 0, 0, 0.75)';

// Content
export const CONTENT_PRIMARY: Color = BLACK;
export const CONTENT_SECONDARY: Color = GRAY_600;
export const CONTENT_TERTIARY: Color = GRAY_500;
export const CONTENT_DISABLED: Color = GRAY_300;

export const CONTENT_DARK_PRIMARY: Color = WHITE;
export const CONTENT_DARK_SECONDARY: Color = GRAY_300;
export const CONTENT_DARK_TERTIARY: Color = GRAY_400;
export const CONTENT_DARK_DISABLED: Color = GRAY_600;

// Border
export const BORDER_DEFAULT: Color = GRAY_200;
export const BORDER_TRANSLUCENT: Color = 'rgba(0, 0, 0, 0.08)';
export const BORDER_SELECTED: Color = BLACK;

export const BORDER_DARK_DEFAULT: Color = GRAY_700;
export const BORDER_DARK_TRANSLUCENT: Color = 'rgba(255, 255, 255, 0.2)';
export const BORDER_DARK_SELECTED: Color = WHITE;

// Others
export const TRANSPARENT = 'transparent';

// DarkMode Variables
export type COLOR_TYPES = 
  'BACKGROUND_PRIMARY' |
  'BACKGROUND_SECONDARY' |
  'BACKGROUND_TERTIARY' |
  'BACKGROUND_TRANSLUCENT' |
  'CONTENT_PRIMARY' |
  'CONTENT_SECONDARY' |
  'CONTENT_TERTIARY' |
  'CONTENT_DISABLED' |
  'BORDER_DEFAULT' |
  'BORDER_TRANSLUCENT' |
  'BORDER_SELECTED';

export const darkTheme: Theme = {
  BACKGROUND_PRIMARY: BACKGROUND_DARK_PRIMARY,
  BACKGROUND_SECONDARY: BACKGROUND_DARK_SECONDARY,
  BACKGROUND_TERTIARY: BACKGROUND_DARK_TERTIARY,
  BACKGROUND_TRANSLUCENT: BACKGROUND_DARK_TRANSLUCENT,
  BORDER_DEFAULT: BORDER_DARK_DEFAULT,
  BORDER_SELECTED: BORDER_DARK_SELECTED,
  BORDER_TRANSLUCENT: BORDER_DARK_TRANSLUCENT,
  CONTENT_DISABLED: CONTENT_DARK_DISABLED,
  CONTENT_PRIMARY: CONTENT_DARK_PRIMARY,
  CONTENT_SECONDARY: CONTENT_DARK_SECONDARY,
  CONTENT_TERTIARY: CONTENT_DARK_TERTIARY,
  darkMode: true
};

export const lightTheme: Theme = {
  BACKGROUND_PRIMARY,
  BACKGROUND_SECONDARY,
  BACKGROUND_TERTIARY,
  BACKGROUND_TRANSLUCENT,
  BORDER_DEFAULT,
  BORDER_SELECTED,
  BORDER_TRANSLUCENT,
  CONTENT_DISABLED,
  CONTENT_PRIMARY,
  CONTENT_SECONDARY,
  CONTENT_TERTIARY,
  darkMode: false
};
