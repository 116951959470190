import { TypeMap } from '@core/util';
import styled from '@emotion/styled';
import { CommonText, CommonTextProps } from '../CommonText.style';
import { SCREEN_BREAKPOINT } from '@components/Grid/Grid.style';

type SizeTypes = 'large' | 'medium' | 'small' | 'xsmall';
interface StyledLabelProps extends CommonTextProps {
  size: SizeTypes;
}

type LabelData = {
  size: string,
  height: string,
}

const sizeMap: TypeMap<SizeTypes, LabelData> = {
  large: { size: '18px', height: '1.33' },
  medium: { size: '16px', height: '1.25' },
  small: { size: '14px', height: '1.14' },
  xsmall: { size: '12px', height: '1.33' },
};


const mobile: TypeMap<SizeTypes, LabelData> = {
  large: { size: '16px', height: '1.25' },
  medium: { size: '14px', height: '1.14' },
  small: { size: '12px', height: '1.33' },
  xsmall: { size: '10px', height: '1.25' },
};



export const StyledLabel = styled(CommonText)<StyledLabelProps>`
  font-size: ${({ size }) => sizeMap[size].size};
  line-height: ${({ size }) => sizeMap[size].height};

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    font-size: ${({ size }) => mobile[size].size};
    line-height: ${({ size }) => mobile[size].height};
  }
`;
