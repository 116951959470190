import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './pages/App';
//import './lang/i18n';
import store from './redux';

/*
import ChannelTalk from './lib/ChannelTalk';
ChannelTalk.boot({
  "pluginKey": "23fbb1d9-d4fd-4831-b008-a189386448fa"
});
*/

import registerServiceWorker from './registerServiceWorker';


const root: HTMLElement | null = document.getElementById('root');
const a = document.getElementById('root');



let shouldHydrate = false;
if(root instanceof HTMLElement) {
  shouldHydrate = root.hasChildNodes();
} else {
  shouldHydrate = false;
}

if (shouldHydrate) {
  ReactDOM.hydrate(
    <Provider store={store}>
      <BrowserRouter>
          <App />
      </BrowserRouter>
    </Provider>, root
  );
} else {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
          <App />
      </BrowserRouter>
    </Provider>, root
  );
}

registerServiceWorker();