
import styled from "@emotion/styled";
import { FlexStyle, Vertical } from "@assets/styled/flex";

import { StyledDisplay, StyledLabel, StyledHeading, StyledParagraph } from "@components/Text";
import { SCREEN_BREAKPOINT, StyledGridRowBtn } from "@components/Grid/Grid.style";


export const StyledDownloadSubmit = styled(FlexStyle)`
    display: flex;
    flex-direction: row;
    
    padding: 16px 32px;
    
    height: 52px;

    border-radius: 8px;
    border : none;
    cursor: pointer;
    align-items : center;
    justify-content : center;

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
      width: calc(50% - 8px);
    }

`;



export const StyledDownloadPolicyBox = styled.div`
    width : 100%;
    display : flex;
    align-items : center;
    text-align : center;
    justify-content : center;
    gap : 2px;
`;

export const StyledDownloadPolicy = styled(StyledLabel)`

`;


export const StyledDownloadPolicyLink = styled.a`
  position: relative; 
  display: inline-block;
`;

export const StyledDownloadPolicyLinkText = styled(StyledLabel)`
  position: relative;
  z-index: 1;
  color : #2A70F0;
`;

export const StyledUnderline = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  height: 1px;
  background-color: #2A70F0;
`;


export const ModalContainer = styled.div`
 
  width : 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 999;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    width : 100%;
    height : 100%;
    
  }


`;

export const DialogBox = styled.dialog`

  width: 800px;
  height: auto;

  display: flex;
  flex-direction : column;
  padding : 32px;
  border: none;
  border-radius: 16px;
  box-shadow: 0 0 30px rgba(30, 30, 30, 0.185);
  box-sizing: border-box;
  background-color: white;
  z-index: 9999;

  margin: 0 auto;
  

  @media (max-width: 800px),(max-height: 760px) {

    padding : 24px;

    width : 100%;
    height : 100%;
    display : flex;
    flex-direction : column;

    top: 64px;

  }


`;


export const DialogSpacing = styled.div`
  @media (max-width: 800px),(max-height: 760px) {
    padding-top : 24px;

  }

`

export const DialogBorder = styled.div`

  @media (max-width: 800px),(max-height: 760px) {
    width : calc(100% + 48px);
    margin : 0 -24px;
    border-bottom : 1px solid rgba(0,0,0,0.08);

  }
`

export const DialogTextBox = styled.div`
  display : flex;
  flex-direction : column;

  padding-bottom : 8px;


  @media (max-width: 800px),(max-height: 760px) {
    padding-bottom : 24px;

  }

`

export const DialogBottomSection = styled.div`
  display : flex;
  flex-direction : column;
  
  @media (max-width: 800px),(max-height: 760px) { 
    margin-bottom : 80px;
    overflow-y: auto;
    scrollbar-width: none;
    scrollbar-color: transparent transparent;
  }

  @media (max-width: 800px), (max-height: 760px) and (-webkit-min-device-pixel-ratio: 0) {
    &::-webkit-scrollbar {
      width: 0px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }

`

export const DialogReactText = styled.div`

  display : flex;
  height : auto;

  @media (max-width: 560px) {
    flex-direction : column;
`




export const DialogMainSection = styled.div`
  display : flex;
  flex-direction : row;
  gap : 32px;
  max-height: 100%;
  @media (max-width: 800px),(max-height: 760px) { 
    flex-direction : column;
  }
`


export const DialogProductSection = styled.div`
  width : 100%;
  display : flex;
  flex-direction : column;

`


export const DialogProductBox = styled.div`
  margin : 4px 0px;
  height : 40px;
  width : 100%;
  display : flex;
  align-items : center;

`

export const DialogProductImg = styled.img`
  width : 40px;
  height : 40px;
  margin-right : 16px;
  object-fit : contain;

  background-repeat: no-repeat;
  display : flex;
  align-items : center;
  justify-content : center;
`

export const DialogProductText = styled(StyledLabel)` 
  width : 100%;
  margin-right : 16px;

`

export const DialogProductPrice = styled(StyledLabel)`
  margin-left : auto;
  display: flex;
  align-items: center;
`





export const DialogRequestBox = styled.div`
  width : 100%;
  display : flex;
  flex-direction : column;

`


export const DialogInputText = styled.div<{inputValue : boolean}>`
  font-family: Pretendard;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  color: ${({ inputValue }) => (inputValue ? 'black' : '#818181')};


`


export const DialogInput = styled.input`
    border : none;
    height : 20px;
    width : 100%;
    font-size : 16px; 
    outline: none;

    &::placeholder {
      color: #BDBDBD;
    }
    
`;




export const DialogInputBox = styled.div<{inputValue : boolean}>`
    width: 100%;
    height: 56px;
    border-radius : 8px;

    padding : 8px 12px;

    color : #BDBDBD;

    border: ${({ inputValue }) => (inputValue ? '1px solid black' : '1px solid rgba(0, 0, 0, 0.08)')};

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
      width : 100%;
    }
    
`;

export const DialogInputBoxRequest = styled.div<{inputValue : boolean}>`
    width: 100%;
    height: 140px;
    
    border-radius : 8px;

    padding : 8px 12px;

    color : #BDBDBD;

    border: ${({ inputValue }) => (inputValue ? '1px solid black' : '1px solid rgba(0, 0, 0, 0.08)')};

    overflow-wrap: break-word;
    align-items: flex-start;

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
      width : 100%;
    }
    
`;



export const DialogInputRequest = styled.textarea`
  border: none;
  font-size: 16px;
  outline: none;
  width: 100%;
  height : 100%;
  flex: 1; 
  resize: none; 
  margin: 0;
  padding: 0;
  
  &::placeholder {
    color: #BDBDBD;
  }
`;

export const DialogCheckBox = styled.img`
  width : 20px;
  height : 20px;
  cursor : pointer;
  margin-right : 8px;
`


export const DialogRequestCancelBtn = styled.div`
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background-color: #FFFFFF;

  cursor : pointer;

`

export const DialogRequestBtn = styled.div<{submit : boolean}>`
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width : fit-content;
  margin : auto;
  cursor: ${({ submit }) => (submit ? 'pointer' : 'text')};
  background-color: ${({ submit }) => (submit ? '#2A70F0' : '#BFD4FA')};

`




export const ClearButton = styled.button`
  background: none;
  border: none;
  width : 20px;
  height : 20px;
  margin-left : auto;
  cursor: pointer;

  display : flex;
`;


export const ClearIcon = styled.img`

  width : 20px;
  height : 20px;
`;


export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.25);
`;


