import React from "react";
import FalldownPage from "./Components/Falldown/Falldown";
import Hardware from "./Components/Hardware/Hardware";
import Installation from "./Components/Installation/Installation";
import Request from "./Components/Request/Request";
import Advantage from "./Components/Advantage/Advantage";
import Banner from "./Components/Banner/Banner";
import Function from "./Components/Function/Function";
import { FontPage } from "@pages/CommonStyle/CommonStyle";
import SEO from "@pages/SEO";
import seo from "@src/lang/ko/seo";



const Home = () => {

  const seo_title = seo.default.title;
  const seo_key = seo.default.key;
  const seo_desc = seo.default.desc;
  const seo_img = seo.default.img;
  const seo_url = seo.default.url;
  
  return (
    <>
      <SEO 
        lang={"ko"}
        helmetTitle={seo_title} 
        ogKey={seo_key} 
        ogDesc={seo_desc} 
        ogImg={seo_img} 
        ogUrl={seo_url}/>

      <FontPage>
        <Banner/>
        <Request/>
      </FontPage>
    </>
  )
}

export default Home;