import { FlexStyle, Vertical } from "@assets/styled/flex";
import { StyledGridColumnProps } from "@components/Grid/Grid.model";
import { SCREEN_BREAKPOINT, StyledGridColumn, StyledGridContainer, StyledGridRow } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading, StyledLabel, StyledParagraph } from "@components/Text";
import styled from "@emotion/styled";



export const BannerPage = styled.div`
  
  height : 1100px;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    height : 860px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    height : 800px;
  }
  
  & * {
    font-family: Pretendard !important; 
  }

`;


export const BannerSection = styled.div`
  height : 100%;
  width: 100%;
  
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: darken;

  display: flex;
  flex-direction: column;
  justify-content: center;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    
    
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    
  }
`;




export const BannerImg = styled.div<{img : any}>`
  width : 100%;
  height : 1100px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: darken;
  
  position : absolute; 

  ${({img}) => img && `background-image : url(${img});`}

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    height : 860px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    height : 800px;
  }

`


export const ImageInner = styled(StyledGridContainer)`
  height : 1100px;

  display : flex; 


  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    height : 860px;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    height : 800px;
    
  }
`;



export const ImageInnerBox = styled.div<StyledGridColumnProps>`
  width : 640px;
  display: grid;

  -ms-grid-column-span: ${({ desktop }) => desktop};
  grid-column-end: span ${({ desktop }) => desktop};

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    width : 100%;
    -ms-grid-column-span: ${({ tablet }) => tablet};
    grid-column-end: span ${({ tablet }) => tablet};
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    width : 100%;
    -ms-grid-column-span: ${({ mobile }) => mobile};
    grid-column-end: span ${({ mobile }) => mobile};
  }


`;



export const BannerData = styled.div`
  display : flex;
  flex-direction : column;
  width : 100%;
  height : 100%; 

  justify-content : center;

`




export const StyledBtnBox = styled.div`

  display: flex;
  flex-direction : row;
  width : 100%;
  gap : 16px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    justify-content : center;
  }

`

export const BannerButton = styled.div`
  padding : 16px 24px;

  justify-content: center;
  align-items: center;
  border-radius : 12px;
  background-color : #2A70F0;
  cursor : pointer;
`;


export const BannerButtonRequest = styled.div`
  padding : 16px 24px;

  justify-content: center;
  align-items: center;
  border-radius : 12px;

  cursor : pointer;

  background-color : #FFFFFF;
  margin-left : 8px;
`;

export const BannerButtonRequestA = styled.a`
  padding : 16px 24px;

  justify-content: center;
  align-items: center;
  border-radius : 12px;

  cursor : pointer;

  background-color : #FFFFFF;
  margin-left : 8px;
`;


export const BannerBtnText = styled(StyledLabel)`
  color : #000000;


`;


export const TextCenter = styled.div`
  width : 50%;
  display : flex;
  justify-content : center;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
    width : 60%;
  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    width : 100%;
  }
`;


export const TextCenterColumnFlex = styled.div`
  width : 100%;
  display : flex;
  text-align : center;
  justify-content : center;
  flex-direction : column;
`;
