

export default {


  info : {
   
    copyright: "2023 KONGtech. Co., Ltd.",
    info: "콩테크 주식회사 ",
    info2: "| 대표 : 이학경 <br/>",
    info3: "사업자 등록 번호 : 129-86-91785",
    privacyPolicy: "개인정보 처리방침",
    servicePolicy: "서비스 이용약관",
    locationPolicy: "위치기반 서비스 이용약관"

  },


  data : [

    {
      title : '지원',
      page : [
        {name : '기술', link : '/enterprise/technology/uwb'},
        {name : '블로그', link : '/blog'},
        {name : '영업문의', link : '/request'},
        {name : '회사소개', link : '/company'},
      ]
    },
    {
      title : '파트너',
      page : [
        {name : '오브로 홈',link : '/'},
        {name : '콩체크', link : 'https://check.kong-tech.com/', backLink:'1'},
        {name : '플루토콘', link : 'https://plutocon.io/', backLink:'1'},
      ]
    },
    {
      title : '고객 센터',
      page : [
        {name : '전화 : 1522 - 9928'},
        {name : '이메일 : orbro@orbro.io'},
      ]
    },

  ]


}