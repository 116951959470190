import { TypeMap } from '@core/util';
import styled from '@emotion/styled';
import { CommonText, CommonTextProps } from '../CommonText.style';

type SizeTypes = 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall';
interface StyledParagraphProps extends CommonTextProps {
  size: SizeTypes;
}

type ParagraphData = {
  size: string,
  height: string,
}

const sizeMap: TypeMap<SizeTypes, ParagraphData> = {
  xlarge: { size: '20px', height: '30px' },
  large: { size: '18px', height: '1.56' },
  medium: { size: '16px', height: '1.5' },
  small: { size: '14px', height: '1.43' },
  xsmall: { size: '12px', height: '1.33' },
};

export const StyledParagraph = styled(CommonText)<StyledParagraphProps>`
  font-size: ${({ size }) => sizeMap[size].size};
  line-height: ${({ size }) => sizeMap[size].height};
`;
