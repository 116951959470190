import React, { useEffect, useMemo } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { DefaultLayout } from '@layouts/index';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import {

  Home

} from './index';

import '@css/app.styl';
import { useDispatch, useSelector } from 'react-redux';
import { setLayoutType, setGridLayoutType } from '@redux/action-creators/common';

import { SCREEN_BREAKPOINT } from '@components/Grid/Grid.style';
import { Global, ThemeProvider } from '@emotion/react';
import global from '@assets/styled/global';

import { darkTheme, lightTheme } from '@assets/styled/color.style';
import { CommonState } from '@redux/reducers/commonReducer';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-PRJDZV4Q'
}

const GOOGLE_ANALYTICS_ID = 'G-XL7Y3DRNJD';


const App = () => {
  // React
  const location = useLocation();
  const dispatch = useDispatch();

  // Redux
  const darkMode = useSelector((state: CommonState) => state.darkMode);

  // Hooks
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.send('pageview');
    }

    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize(GOOGLE_ANALYTICS_ID, { gtagOptions: { debug_mode: true } });
      TagManager.initialize(tagManagerArgs);
    }

    const handleResize = () => {
      const { innerWidth } = window;

      if (innerWidth >= 1221) {
        dispatch(setLayoutType('desktop'));
      } else if (innerWidth > 721) {
        dispatch(setLayoutType('tablet'));
      } else {
        dispatch(setLayoutType('mobile'));
      }

      if (innerWidth > SCREEN_BREAKPOINT.TABLET) {
        dispatch(setGridLayoutType('desktop'));
      } else if (innerWidth > SCREEN_BREAKPOINT.MOBILE) {
        dispatch(setGridLayoutType('tablet'));
      } else {
        dispatch(setGridLayoutType('mobile'));
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Theme
  const theme = useMemo(() => (darkMode ? darkTheme : lightTheme), [darkMode]);

  return (
    <>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <Global styles={global} />
          <Routes>

            <Route path="/" element={<DefaultLayout />}>

            <Route path="/" element={<Home/>} />

            
            </Route>

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </ThemeProvider>
      </I18nextProvider>
    </>
  );
};

export default App;
