import { CommonActionType } from '@redux/action-types/common';
import { CommonAction, LayoutType } from '@redux/actions/common';

export interface CommonState {
  layoutType: LayoutType;
  gridLayoutType: LayoutType;
  darkMode: boolean;
  selectedValue: number;
  selectedProduct: number;
}

const initialState: CommonState = {
  layoutType: 'desktop',
  gridLayoutType: 'desktop',
  darkMode: false,
  selectedValue: 0,// 초기값 설정
  selectedProduct:0
};

const commonReducer = (state: CommonState = initialState, action: CommonAction) => {
  switch (action.type) {
    case CommonActionType.SET_LAYOUT_TYPE:
      return {
        ...state,
        layoutType: action.payload,
      };
    case CommonActionType.SET_GRID_LAYOUT_TYPE:
      return {
        ...state,
        gridLayoutType: action.payload
      }
    case CommonActionType.SET_DARK_MODE:
      return {
        ...state,
        darkMode: action.payload
      }
    case CommonActionType.SET_SELECTED_VALUE: // 새로운 액션 타입 추가
      return {
        ...state,
        selectedValue: action.payload,
      };

    case CommonActionType.SET_SELECTED_PRODUCT: // 새로운 액션 타입 추가
      return {
        ...state,
        selectedProduct: action.payload,
      };
    default:
      return state;
  }
};

export default commonReducer;
