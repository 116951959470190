
export default {


    default : {
        title : '사무실 스마트 출입 시스템 | 오브로 오피스',
        key :'자동문, 도어락, 스피드게이트, 출입문, 출입시스템, 스마트 출입, 사무실, 출퇴근 관리',
        desc : '자동문, 도어락, 스피드게이트, 출입 시스템 등 오브로 오피스로 사무실 출입을 스마트하게 컨트롤 할 수 있습니다. 가장 빠르고 안전하며 정확합니다.',
        img : '',
        url : 'https://orbrohome.io',
    },


}