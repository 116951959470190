
export default {


    banner : {
        download : '자료 다운로드',
        request : '견적 확인'
    },

    modal : {

        title : '현재 자료를 준비중입니다.',
        desc : '빠른 시일 내로 자료가 업데이트 될 예정입니다.<br/>조금만 기다려주시면 감사하겠습니다.',

        download : {
            title : '자료 다운로드',
            desc : '아래 항목에 정보를 입력해 주시면, 이메일로 자료를 보내드리겠습니다. 기타 문의사항이 필요하신 경우, ',
            phone : '(+82)1522-9928',
            descTail : ' 로 연락 주시면 감사하겠습니다.',
            time : '평일 (10:00 - 19:00) (KST)',
            ph_email : 'example@orbro.io',
            
            agree1 : '[필수]',
            agree2 : '개인정보 처리방침',
            agree3 : '에 동의합니다.',

            confirm : '다운로드',
            cancel : '취소'
        },
        subscribe : {
            title : '발송 완료',
            desc : '로 자료를 발송해 드렸습니다.<br/>오브로의 뉴스레터를 구독하여 기술 및 다양한 정보를 받아 보시겠습니까?',
            skip : '건너뛰기',
            confirm : '구독하기'
        },
        done : {
            title : '구독 완료',
            desc : '오브로의 뉴스레터를 구독해주셔서 감사합니다. 구독자님들께는 최신 소식과 정보를 이메일로 보내드립니다. 오브로와함께 더 많은 정보와 지식을 얻어보세요.',
            confirm : '확인'
        }


    },

    request : {
        title : '문의하기', 
        desc : `솔루션 도입이 필요하시면 아래의 정보 입력후 '제출' 버튼을 클릭해주세요.`,

        name : '홍길동',



        placeholder : {
            name : '이름'
        }

    },


    contents : {
        title : '다양한 컨텐츠 알아보기',

        tag : {
            construction : '건설',
            logistics : '물류',
            office : '오피스',
            medical : '의료',
            manufacturing : '제조',
            retail : '리테일',

        }
        
    },

    news : {
        title : '다양한 소식을 받아보세요!'
    }


}