import { AdvantageImgD, AdvantageImgM, AdvantageImgT, 
    
    Falldown1D, Falldown1M, Falldown1T, 
    Falldown2D, Falldown2M, Falldown2T, 
    Falldown3D, Falldown3M, Falldown3T, 
    
    HomeBannerD, HomeBannerM, HomeBannerT, 
    
    
    functionImg1D, functionImg1M, functionImg1T, 
    functionImg2D, functionImg2M, functionImg2T, 
    functionImg3D, functionImg3M, functionImg3T, 
    functionImg4D, functionImg4M, functionImg4T, 
    functionImg5D, functionImg5M, functionImg5T, 
    functionImg6D, functionImg6M, functionImg6T, 
    functionImg7D, functionImg7M, functionImg7T, 
    
    hardwareImg1D, hardwareImg1M, hardwareImg1T,
    hardwareImg2D, hardwareImg2M, hardwareImg2T,
    hardwareImg3D, hardwareImg3M, hardwareImg3T,
    hardwareImg4D, hardwareImg4M, hardwareImg4T,
    hardwareImg5D, hardwareImg5M, hardwareImg5T,

    installationImg1D, installationImg1M, installationImg1T,
    installationImg2D, installationImg2M, installationImg2T,
    installationImg3D, installationImg3M, installationImg3T,
    installationImg4D, installationImg4M, installationImg4T,
    installationImg5D, installationImg5M, installationImg5T 

} from "@assets/images/home";
    
import { BlueIconPhone, BlueIconRFID, BlueIconRemote, BlueIconWatch, BlueIconWidget } from "@assets/images/icon/blue";

export default {

    banner : {
        title : '사무실 출입 관리,<br/>ORBRO로 시작하세요.',
        desc : '오브로 출입 시스템은 지속적으로 변화하는 현대사회의 다양한 출입 문화를 선도합니다. 기존의 전통적인 출입방식부터 스마트폰을 이용한 출입 방식까지 사무 공간에서 편의성과 안전성을 경험하세요.',
        img : {
            desktop : HomeBannerD,
            tablet : HomeBannerT,
            mobile : HomeBannerM
        }
    },

    falldown : {
        left : {
            title : '출입 방식을 스마트폰에 통합,<br/>가장 현대적인 출입 시스템',
            desc : '일반문에서부터 자동문, 스피드게이트까지 현대사회의 다양한 출입문을 스마트폰으로 간편하게 이용할 수 있습니다. 우리의 일상과 가장 밀접한 시스템을 이용하여 가장 효과적인 디지털 전환을 경험하세요.',
        
            fall : [
                {
                    title : '스마트폰 출입',
                    desc : 'iOS/Android 모든 스마트폰을통해 출입이 가능합니다.',
                    img : {
                        desktop : Falldown1D,
                        tablet : Falldown1T,
                        mobile : Falldown1M
                    }
                },
                {
                    title : '스마트 워치 출입',
                    desc : '스마트 워치를 통해 더 편리한 출입 경험이 가능합니다.',
                    img : {
                        desktop : Falldown2D,
                        tablet : Falldown2T,
                        mobile : Falldown2M
                    }
                },
                {
                    title : 'RFID 카드 출입',
                    desc : '기존의 사원증 카드를 활용하여 출입이 가능합니다.',
                    img : {
                        desktop : Falldown3D,
                        tablet : Falldown3T,
                        mobile : Falldown3M
                    }
                },
            ]
        },
    
        rightImg : ''
    },

    hardware : {
        title : '핵심은 하드웨어,<br/>모든것이 가볍게 혁신.',
        card : [
            {  
                img : {
                    desktop : hardwareImg1D,
                    tablet : hardwareImg1T,
                    mobile : hardwareImg1M
                },
                tag : '상태 LED',
                title : 'NFC/Bluetooth<br/>듀얼채널 방식의 출입',
                desc : 'NFC카드 출입은 물론,<br/>모바일 출입, 스마트 워치 출입까지 가능합니다.'
            },
            {  
                img : {
                    desktop : hardwareImg2D,
                    tablet : hardwareImg2T,
                    mobile : hardwareImg2M
                },
                tag : '전원 공급',
                title : 'PoE 사용으로,<br/>통신과 전원이 통합',
            },
            {  
                img : {
                    desktop : hardwareImg3D,
                    tablet : hardwareImg3T,
                    mobile : hardwareImg3M
                },
                tag : '간편한 설치',
                title : '전용 브라켓으로<br/>간편한 설치',
            },
            {  
                img : {
                    desktop : hardwareImg4D,
                    tablet : hardwareImg4T,
                    mobile : hardwareImg4M
                },
                tag : '스피커',
                title : '스피커를 통한<br/>출입 알림',
            },
            {  
                theme : 'black',
                img : {
                    desktop : hardwareImg5D,
                    tablet : hardwareImg5T,
                    mobile : hardwareImg5M
                },
                tag : '상태 LED',
                darkTitle : '어두운밤에도<br/>',
                lightTitle : '상태확인 ',
                darkTitle2 : '가능',
                desc : 'RGB LED 제공을통해<br/>기기의 상태, 출입 확인 등이 가능합니다.'
            },
        ]

    },

    function : {

        title : '누구나 쉽게 사용 가능한<br/>소프트웨어',
        desc : '오브로는 8년간 출입 관리 경험을 토대로 소프트웨어를 개발하였습니다. 사용자 피드백과 요구사항을 반영하여 디자인 되었으며,<br/>현장에서의 편의성을 극대화 하였습니다. 이는 실제 사용자들의 요구를 반영하여 개발된 결과물로, 출입 관리에서의 실질적인 혜택을 제공합니다',

        card : [
            {
                tag : '사용자 등록',
                title : '휴대폰번호, 이메일로<br/>사용자 초대 가능',
                label : '관리자',
                img : {
                    desktop : functionImg1D,
                    tablet : functionImg1T,
                    mobile : functionImg1M
                },
            },
            {
                tag : '출입 권한 관리',
                title : '손쉬운<br/>출입권한 관리',
                label : '관리자',
                desc : '출입 가능한 기기만 등록하면<br/>출입 권한 생성 끝.',
                img : {
                    desktop : functionImg2D,
                    tablet : functionImg2T,
                    mobile : functionImg2M
                },
            },
            {
                tag : '엑셀 다운로드',
                title : '출입 기록, 사용자 정보<br/>다운로드 제공',
                label : '관리자',
                img : {
                    desktop : functionImg3D,
                    tablet : functionImg3T,
                    mobile : functionImg3M
                },
            },
            {
                tag : '원격 제어',
                title : '원격 출입문<br/>제어 가능',
                label : '관리자',
                img : {
                    desktop : functionImg4D,
                    tablet : functionImg4T,
                    mobile : functionImg4M
                },
            },
            {
                tag : '자동출입',
                title : '앱을 켜지않아도<br/>출입 가능',
                label : '사용자',
                img : {
                    desktop : functionImg5D,
                    tablet : functionImg5T,
                    mobile : functionImg5M
                },
            },
            {
                tag : '모바일 위젯',
                title : '빠른 앱 실행이 가능한<br/>위젯 제공',
                label : '사용자',
                img : {
                    desktop : functionImg6D,
                    tablet : functionImg6T,
                    mobile : functionImg6M
                },
            },
            {
                tag : '자동출입',
                title : '문이 열리는 거리를<br/>쉽게 설정',
                label : '사용자',
                img : {
                    desktop : functionImg7D,
                    tablet : functionImg7T,
                    mobile : functionImg7M
                },
            },
        ]
    },


    advantage : {
        img : {
            desktop : AdvantageImgD,
            tablet : AdvantageImgT,
            mobile : AdvantageImgM,
        },
        title : '오브로가<br/>기존 출입 시스템과 다른점',
        desc : '현대 사회에 변화하고 있는 다양한 출입방식을 하나의 디바이스에서 제공하는, 가장 현대적인 출입 단말기 입니다. 전통적인 출입방식에서부터 최신 스마트 기술까지 다양한 방식으로 출입을 관리하며, 사용자들에게 편리하고 안전한 출입 환경을 제공합니다.',
        card : [
            {
                icon : BlueIconPhone,
                title : '스마트폰을 이용한 출입',
                desc : 'iOS / Android 앱을 이용하여 출입문을 제어 할 수 있습니다.',
            },
            {
                icon : BlueIconWidget,
                title : '위젯 제공',
                desc : '위젯은 모바일 앱을 더 편하게 사용하기 위한 방법입니다.',
            },
            {
                icon : BlueIconWatch,
                title : '스마트 워치를 이용한 출입',
                desc : '스마트 워치를 이용하여 편리한 출입 방식을 경험하세요.',
            },
            {
                icon : BlueIconRemote,
                title : '원격 제어',
                desc : '관리자는 원격에서 출입문을 관리할 수 있습니다.',
            },
            {
                icon : BlueIconRFID,
                title : 'RFID & NFC 카드',
                desc : 'RFID or NFC Tag를 태깅하여 간편하게 출입문을 제어할 수 있습니다.',
            },
        ]

    },


    installation : {
        title : '제품설치, 걱정마세요.<br/>저희가 알아서 다해드려요.',
        card : [
            {  
                img : {
                    desktop : installationImg1D,
                    tablet : installationImg1T,
                    mobile : installationImg1M
                },
                tag : '온프레미스 서버제공',
                title : '엔터프라이즈급<br/>서버 제공',
            },
            {  
                img : {
                    desktop : installationImg2D,
                    tablet : installationImg2T,
                    mobile : installationImg2M
                },
                tag : '전문가 현장 설치 지원',
                title : '1522-9928로 연락주시면<br/>가장 빠른 날짜로 설치 가능',
                desc : '평일 오전 9 ~ 오후 6시',
            },
            {  
                img : {
                    desktop : installationImg3D,
                    tablet : installationImg3T,
                    mobile : installationImg3M
                },
                tag : '출입 액세서리',
                title : '데드볼트 시공 가능',
            },
            {  
                img : {
                    desktop : installationImg4D,
                    tablet : installationImg4T,
                    mobile : installationImg4M
                },
                tag : '출입 액세서리',
                title : 'EM Lock 시공 가능',
            },
            {  
                theme : 'black',
                img : {
                    desktop : installationImg5D,
                    tablet : installationImg5T,
                    mobile : installationImg5M
                },
                tag : '출입 액세서리',
                title : '실내 출입 센서 추가 가능',
            },
        ]

    },


    request : {
        title : '견적 및 도입문의가 필요하신가요?',
        desc1 : "도움이 필요하신가요? ",
        desc2 : '1522-9928',
        desc3 : "로 연락 주시면<br/>출입 전문가가 도와 드리도록 하겠습니다.",
        
        company : '회사 및 기관 이름 *',
        companyPH : '오브로',
        
        name : '담당자 이름 *',
        namePH : '홍길동',
        
        phone : '담당자 연락처 *',
        phonePH : '010-1234-5678',
        
        email : '담당자 이메일 *',
        emailPH : 'email@orbro.io',

        policy1 : '[필수] ',
        policy2 : '개인정보 처리방침 ',
        policy3 : '동의',

        submit : '서비스 도입 문의'
        
    }






}