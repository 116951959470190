import { SCREEN_BREAKPOINT } from '@components/Grid/Grid.style';
import { TypeMap } from '@core/util';
import styled from '@emotion/styled';
import { CommonText, CommonTextProps } from '../CommonText.style';

type SizeTypes = 'large' | 'medium' | 'small';
interface StyledDisplayProps extends CommonTextProps {
  size: SizeTypes;
}

type DisplayMapData = {
  size: string
  height: string
}

const desktopSizeMap: TypeMap<SizeTypes, DisplayMapData> = {
  large: { size: '68px', height: '1.21' },
  medium: { size: '52px', height: '1.23' },
  small: { size: '36px', height: '1.22' },
};

const tabletSizeMap: TypeMap<SizeTypes, DisplayMapData> = {
  large: { size: '52px', height: '1.23' },
  medium: { size: '44px', height: '1.18' },
  small: { size: '32px', height: '1.25' },
};

const mobileSizeMap: TypeMap<SizeTypes, DisplayMapData> = {
  large: { size: '36px', height: '1.22' },
  medium: { size: '32px', height: '1.25' },
  small: { size: '28px', height: '1.29' },
};

export const StyledDisplay = styled(CommonText)<StyledDisplayProps>`
  font-size: ${({ size }) => desktopSizeMap[size].size};
  line-height: ${({ size }) => desktopSizeMap[size].height};
  font-weight: bold;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    font-size: ${({ size }) => tabletSizeMap[size].size}; 
    line-height: ${({ size }) => tabletSizeMap[size].height};
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    font-size: ${({ size }) => mobileSizeMap[size].size};
    line-height: ${({ size }) => mobileSizeMap[size].height};
  }
`;
