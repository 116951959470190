import seo from "@src/lang/ko/seo";


const ko = {
    seo,

};

export default ko;

