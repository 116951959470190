import { CommonActionType } from '@redux/action-types/common';
import { LayoutType, SetDarkMode, SetGridLayoutType, SetLayoutType, SetSelectedProduct, SetSelectedValue } from '@redux/actions/common';

export const setLayoutType = (type: LayoutType): SetLayoutType => {
  return {
    type: CommonActionType.SET_LAYOUT_TYPE,
    payload: type,
  };
};

export const setGridLayoutType = (type: LayoutType): SetGridLayoutType => {
  return {
    type: CommonActionType.SET_GRID_LAYOUT_TYPE,
    payload: type
  }
}

export const setDarkMode = (darkMode: boolean): SetDarkMode => {
  return {
    type: CommonActionType.SET_DARK_MODE,
    payload: darkMode,
  };
};


export const setSelectedValue = (value: number): SetSelectedValue => {
  return {
    type: CommonActionType.SET_SELECTED_VALUE,
    payload: value,
  };
};


export const setSelectedProduct = (value: number): SetSelectedProduct => {
  return {
    type: CommonActionType.SET_SELECTED_PRODUCT,
    payload: value,
  };
};

