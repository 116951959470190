import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading, StyledLabel, StyledParagraph } from "@components/Text";
import { CommonTextProps } from "@components/Text/CommonText.style";
import { TypeMap } from "@core/util";
import styled from "@emotion/styled";


type SizeTypes = 'large' | 'medium' | 'small';
interface StyledDisplayProps extends CommonTextProps {
  size: SizeTypes;
}

type DisplayMapData = {
  size: string,
  height: string,
}



const desktopSizeMap: TypeMap<SizeTypes, DisplayMapData> = {
  large: { size: '68px', height: '1.21' },
  medium: { size: '52px', height: '1.23' },
  small: { size: '36px', height: '1.22' },
};

const tabletSizeMap: TypeMap<SizeTypes, DisplayMapData> = {
  large: { size: '52px', height: '1.23' },
  medium: { size: '44px', height: '1.18' },
  small: { size: '32px', height: '1.25' },
};

const mobileSizeMap: TypeMap<SizeTypes, DisplayMapData> = {
  large: { size: '36px', height: '1.22' },
  medium: { size: '32px', height: '1.25' },
  small: { size: '28px', height: '1.29' },
};


export const FontPage = styled.div`
  & * {
    font-family : Pretendard ! important;
  }
`;

export const SpanUnderline = styled.span`
text-decoration-line : underline;
`;

export const SpanColor = styled.span<{color : string}>`
    ${({color})=> color && `color : ${color};`}
`;
export const SpanBlue = styled.span`
  color : #2A70F0;
`;
export const SpanBlueUnderline = styled.span`
  color : #2A70F0;
  text-decoration-line : underline;
`;

export const SpanFFF = styled.span`
  color : #FFFFFF;
`;

export const Span818181 = styled.span`
  color : #818181;
`;
export const Span00C37D = styled.span`
  color : #00C37D;
`;
export const SpanFF6C61 = styled.span`
  color : #FF6C61;
`;


export const Img32 = styled.img`
  width : 32px;
  height : 32px;
`;

export const ImgWidth100 = styled.img`
  width : 100%;
  max-width : 1232px;
  aspect-ratio : auto 1232 / 771;
`;


export const Spacing = styled.div<{pixel : string}>`

  ${({pixel}) => pixel && `margin-top : ${pixel}px`};

`;

export const DisplayColor = styled(StyledDisplay)<{color : string}>`
  ${({color})=> color && `color : ${color};`}
`;

export const HeadingColor = styled(StyledHeading)<{color : string}>`
  ${({color})=> color && `color : ${color};`}
`;

export const LabelColor = styled(StyledLabel)<{color : string}>`
  ${({color})=> color && `color : ${color};`}
`

export const ParagraphColor = styled(StyledParagraph)<{color : string}>`
  ${({color})=> color && `color : ${color};`}
`



export const BorderBottomLine = styled.div`
  border-bottom : 1px solid rgba(0,0,0,0.08);
`;



export const DisplayH1 = styled.h1<StyledDisplayProps>`

  font-size: ${({ size }) => desktopSizeMap[size].size};
  line-height: ${({ size }) => desktopSizeMap[size].height};
  font-style : bold;
  font-weight : 700;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    font-size: ${({ size }) => tabletSizeMap[size].size}; 
    line-height: ${({ size }) => tabletSizeMap[size].height};
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    font-size: ${({ size }) => mobileSizeMap[size].size};
    line-height: ${({ size }) => mobileSizeMap[size].height};
  }

`;




export const DisplayH2 = styled.h2<StyledDisplayProps>`

  font-size: ${({ size }) => desktopSizeMap[size].size};
  line-height: ${({ size }) => desktopSizeMap[size].height};
  font-style : bold;
  font-weight : 700;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    font-size: ${({ size }) => tabletSizeMap[size].size}; 
    line-height: ${({ size }) => tabletSizeMap[size].height};
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    font-size: ${({ size }) => mobileSizeMap[size].size};
    line-height: ${({ size }) => mobileSizeMap[size].height};
  }

`;



