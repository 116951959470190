import styled from '@emotion/styled';

// Interface
import { FlexProps } from './model/flex.model';

export const FlexStyle = styled.div<FlexProps>(({ auto, column, alignItems, alignSelf, justifyContent, justifySelf, columnGap, rowGap }) => ({
  display: 'flex',
  flex: auto ? 'auto' : 'none',
  flexDirection: column ? 'column' : 'row',
  columnGap: columnGap ? `${columnGap}px` : 0,
  rowGap: rowGap ? `${rowGap}px` : 0,
  alignItems,
  alignSelf,
  justifyContent,
  justifySelf,
}));

export const Horizontal = styled(FlexStyle)`
  flex-direction: row;
`;

export const Vertical = styled(FlexStyle)`
  flex-direction: column;
`;
