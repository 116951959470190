
import { SCREEN_BREAKPOINT, StyledGridColumn, StyledGridRow } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";




export const RequestColor = styled.div`
  background-color : #EAF1FD;
  
  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
    padding : 0 51px;
  }
`;

export const RequestPage = styled.div`
  padding : 128px 0;

  text-align : center;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    padding : 128px 0 96px 0;
  }

`;



export const RequestBox = styled.div`
  width : 100%;
  display : flex;
  flex-direction : row;
  gap : 16px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    flex-direction : column;
    gap : 16px;
  }


`;




export const RequestInputBox = styled.div<{inputValue : boolean}>`
  width: 100%;
  height: 52px;
  border-radius : 8px;

  padding : 16px;

  background-color : #FFFFFF;

  border: ${({ inputValue }) => (inputValue ? '1px solid black' : '1px solid rgba(0, 0, 0, 0.08)')};

`;


export const InputBox = styled.div`
  display : flex;
  flex-direction : column;
  width : 100%;
`;

export const InputDescBox = styled.div`
  margin-right : auto;
`;